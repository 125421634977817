import React, { Component } from "react"

// Styles
import Styles from "./SVGMiniProfil.module.styl"

class SVGMiniProfil extends Component {
    constructor(props) {
        super(props)
    }

    drawSitePolygon() {
        let polygonString = ""

        Object.entries(this.props.siteValues).forEach((entry, index) => {
            // First key is name
            const entryName = entry[0],
                entryValue = entry[1]

            let x, y

            switch (entryName) {
                case "business":
                    x = -entryValue
                    y = 0
                    break
                case "vision":
                    x = 0
                    y = -entryValue
                    break
                case "service":
                    x = entryValue
                    y = 0
                    break
                case "reference":
                    x = 0
                    y = entryValue
                    break
            }

            polygonString += `${x.toString()},${y.toString()} `
        })

        return (
            <g transform="translate(6, 6)">
                <polygon
                    fill="transparent"
                    className={Styles.SVGMiniProfil__graph__path}
                    points={polygonString}
                    stroke="black"
                    vectorEffect="non-scaling-stroke"
                />
            </g>
        )
    }

    render() {
        return (
            <div className={`${Styles.SVGMiniProfil}`}>
                <svg
                    className={`${Styles.SVGMiniProfil__graph}`}
                    viewBox="0 0 12 12"
                >
                    {this.drawSitePolygon()}

                    <line
                        x1="6"
                        y1="0"
                        x2="6"
                        y2="12"
                        stroke="black"
                        strokeWidth="1"
                        vectorEffect="non-scaling-stroke"
                    />
                    <line
                        x1="0"
                        y1="6"
                        x2="12"
                        y2="6"
                        stroke="black"
                        strokeWidth="1"
                        vectorEffect="non-scaling-stroke"
                    />
                </svg>
            </div>
        )
    }
}

export default SVGMiniProfil
