import React, { Component } from "react"

// Libs
import SwiperCore, { Navigation, Pagination } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"

// Styles
import CommonStyles from "./Carousel.module.styl"

// Components
import BoxCarouselIdea from "~components/Boxes/BoxCarouselIdea.js"

// Helpers
import SptkUtils from "~helpers/sptk"

// install Swiper components
SwiperCore.use([Navigation, Pagination])

class CarouselIdeas extends Component {
    constructor(props) {
        super(props)
    }

    renderCarouselItems() {
        const Items = this.props.carouselItems.map((item, index) => {
            return (
                <SwiperSlide key={`carousel-idea-item-${index}`}>
                    <BoxCarouselIdea {...item} />
                </SwiperSlide>
            )
        })

        return Items
    }

    render() {
        const device = new SptkUtils()
        const Items = this.renderCarouselItems()

        let slides = 2

        if (device.utils.isMobileTablet) {
            slides = 1
        }

        return (
            <Swiper
                spaceBetween={-1}
                slidesPerView={slides}
                className={`carousel carousel--ideas`}
                slideActiveClass={`is-active`}
                navigation={{
                    hiddenClass: "is-hidden",
                    disabledClass: "is-disabled",
                }}
                pagination={{
                    clickable: true,
                    bulletElement: "i",
                    bulletClass: "carousel__pagination__item",
                    bulletActiveClass: "is-active",
                }}
            >
                {Items}
            </Swiper>
        )
    }
}

export default CarouselIdeas
