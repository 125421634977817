import React, { Component } from "react"

// Libs
import SwiperCore, { Pagination } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"

// Styles
import CommonStyles from "./Carousel.module.styl"

// Components
import BoxProfil from "~components/Boxes/BoxProfil.js"

// Helpers
import SptkUtils from "~helpers/sptk"

// install Swiper components
SwiperCore.use([Pagination])

class CarouselProfils extends Component {
    constructor(props) {
        super(props)
    }

    renderItems() {
        const Items = this.props.data.items.map((item, index) => {
            if (item.id * 1 === this.props.currentProfilID) {
                return false
            }

            return (
                <SwiperSlide key={`carousel-profil-item-${index}`}>
                    <BoxProfil {...item} />
                </SwiperSlide>
            )
        })

        return Items
    }

    render() {
        const Items = this.renderItems()

        const device = new SptkUtils()

        let space = 48

        if (device.utils.isMobileTablet) {
            space = 0
        }

        return (
            <Swiper
                spaceBetween={space}
                slidesPerView={1}
                className={`carousel carousel--profils`}
                slideActiveClass={`is-active`}
                pagination={{
                    clickable: true,
                    bulletElement: "i",
                    bulletClass: "carousel__pagination__item",
                    bulletActiveClass: "is-active",
                }}
            >
                {Items}
            </Swiper>
        )
    }
}

export default CarouselProfils
