import React, { Component } from "react"
import { Link } from "gatsby"

// Components
import SVGMiniProfil from "~components/SVGs/SVGMiniProfil"
import slugify from "slugify"

// Styles
import Styles from "./BoxProfil.module.styl"

class BoxProfil extends Component {
    constructor(props) {
        super(props)
    }

    renderLink() {
        const slug = slugify(this.props.name, {
            replacement: "-", // replace spaces with replacement character, defaults to `-`
            remove: undefined, // remove characters that match regex, defaults to `undefined`
            lower: true, // convert to lower case, defaults to `false`
            strict: true, // strip special characters except replacement, defaults to `false`
        })

        return (
            <Link
                to={`/sites/${slug}`}
                className={`${Styles.BoxProfil__title} teasing-3`}
            >
                <span
                    className={`result-number result-number--small`}
                >{`${this.props.id.toString().padStart(2, "0")}_ `}</span>
                {this.props.name}
            </Link>
        )
    }

    render() {
        const Link = this.renderLink()

        return (
            <div className={Styles.BoxProfil}>
                <div className={Styles.BoxProfil__inner}>
                    <div className={Styles.BoxProfil__top}>{Link}</div>
                    <div className={Styles.BoxProfil__body}>
                        <div className={Styles.BoxProfil__body__wrapper}>
                            <SVGMiniProfil
                                siteValues={this.props.chartsValues}
                            />
                        </div>
                    </div>
                    <div className={Styles.BoxProfil__bottom}>
                        <p className={`small`}>{this.props.chapo}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default BoxProfil
