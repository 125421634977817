import React, { Component } from "react"

// Libs
import { Element } from "react-scroll"

// Styles
import CommonStyles from "./PanelCommons.module.styl"
import Styles from "./PanelReussir.module.styl"

// Components
import { Row, Cell } from "~layout/Layout"
import CarouselIdeas from "~components/Carousels/CarouselIdeas"
import ListDetails from "~components/Lists/ListDetails"

class PanelReussir extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Element
                name="reussir"
                className={`${Styles.PanelReussir} ${CommonStyles.PanelResultat}`}
            >
                <Row>
                    <Cell start="0" end="12">
                        <div className={Styles.PanelReussir__inner}>
                            <div className={Styles.PanelReussir__top}>
                                <h2 className="heading-4">
                                    Des idées pour
                                    <br />
                                    réussir votre projet
                                </h2>
                                <div className={Styles.PanelReussir__carousel}>
                                    <CarouselIdeas
                                        carouselItems={this.props.carouselItems}
                                    />
                                </div>
                            </div>
                            {!this.props.isMailWalled &&
                                this.props.detailsItems.length > 0 && (
                                    <div className={Styles.PanelReussir__body}>
                                        <h3 className="heading-3-bis">
                                            En détails
                                        </h3>
                                        <ListDetails
                                            detailsItems={
                                                this.props.detailsItems
                                            }
                                        />
                                    </div>
                                )}
                        </div>
                    </Cell>
                </Row>
            </Element>
        )
    }
}

export default PanelReussir
