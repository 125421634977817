import React, { Component } from "react"

// Libs
import { Element } from "react-scroll"

// Styles
import CommonStyles from "./PanelCommons.module.styl"
import Styles from "./PanelPriorites.module.styl"

// Components
import { Row, Cell } from "~layout/Layout"
import ExpandsController from "~components/Expands/ExpandsController"

class PanelPriorites extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Element
                name="priorites"
                className={`${Styles.PanelPriorites} ${CommonStyles.PanelResultat}`}
            >
                <Row>
                    <Cell start="0" end="12">
                        <div className={Styles.PanelPriorites__inner}>
                            <div className={Styles.PanelPriorites__top}>
                                <h3
                                    className={`${Styles.PanelPriorites__title} heading-3-bis`}
                                >
                                    Les priorités pour le site{" "}
                                    {this.props.title}
                                </h3>
                                <ExpandsController
                                    closeOthers={true}
                                    scrollTo={true}
                                    type={"priorite"}
                                    items={this.props.items}
                                />
                            </div>
                        </div>
                    </Cell>
                </Row>
            </Element>
        )
    }
}

export default PanelPriorites
