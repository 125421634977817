import React, { Component } from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"

// Libs
import slugify from "slugify"
import { Element } from "react-scroll"
import SptkUtils from "~helpers/sptk"

// Styles
import CommonStyles from "./PanelCommons.module.styl"
import Styles from "./PanelPlusLoin.module.styl"
import ButtonsStyles from "~components/Buttons/ButtonSecondary.module.styl"

// Components
import { Row, Cell } from "~layout/Layout"
import CarouselProfils from "~components/Carousels/CarouselProfils"
import LinkPrimary from "~components/Links/LinkPrimary"
import BoxProfil from "~components/Boxes/BoxProfil.js"

class PanelPlusLoin extends Component {
    constructor(props) {
        super(props)

        this.helpers = new SptkUtils()
    }

    sortData(data) {
        const families = {}

        for (let i = 0, j = data.length; i < j; i++) {
            const currentData = data[i].node

            const slug = slugify(currentData.families.primary, {
                replacement: "-", // replace spaces with replacement character, defaults to `-`
                remove: undefined, // remove characters that match regex, defaults to `undefined`
                lower: true, // convert to lower case, defaults to `false`
                strict: true, // strip special characters except replacement, defaults to `false`
            })

            if (typeof families[slug] === "undefined") {
                families[slug] = {}
                families[slug]["items"] = []
                families[slug]["name"] = currentData.families.primary
                families[slug]["slug"] = slug
            }

            families[slug]["items"].push(currentData)
        }

        this.families = families
    }

    renderMobileContentWithData(data) {
        this.sortData(data)

        return (
            <CarouselProfils
                data={this.families[this.props.family.slug]}
                currentProfilID={this.props.profilID}
            />
        )
    }

    renderDesktopContentWithData(data) {
        this.sortData(data)

        const Items = this.families[this.props.family.slug].items.map(
            (item, index) => {
                if (item.id * 1 === this.props.profilID) {
                    return false
                }

                return (
                    <BoxProfil
                        key={`desktop-box-profil-item-${index}`}
                        {...item}
                    />
                )
            }
        )

        return (
            <div className={`${Styles.PanelPlusLoin__profiles}`}>{Items}</div>
        )
    }

    renderCTA() {
        const slug = slugify(this.props.cta.niceName, {
            replacement: "-", // replace spaces with replacement character, defaults to `-`
            remove: undefined, // remove characters that match regex, defaults to `undefined`
            lower: true, // convert to lower case, defaults to `false`
            strict: true, // strip special characters except replacement, defaults to `false`
        })

        return (
            <LinkPrimary to={`/sites/${slug}`} title={this.props.cta.label} />
        )
    }

    render() {
        return (
            <Element
                name="plus_loin"
                className={`${Styles.PanelPlusLoin} ${CommonStyles.PanelResultat}`}
            >
                <Row>
                    <Cell start="0" end="12">
                        <div className={Styles.PanelPlusLoin__inner}>
                            <div className={Styles.PanelPlusLoin__left}>
                                <h2
                                    className={`heading-3-bis ${Styles.PanelPlusLoin__title}`}
                                    dangerouslySetInnerHTML={{
                                        __html: this.props.title,
                                    }}
                                ></h2>
                                <p
                                    className={`${Styles.PanelPlusLoin__text}`}
                                    dangerouslySetInnerHTML={{
                                        __html: this.props.chapo,
                                    }}
                                ></p>
                                {this.props.cta.label &&
                                    this.props.cta.slug && (
                                        <div
                                            className={`${Styles.PanelPlusLoin__link}`}
                                        >
                                            {this.renderCTA()}
                                        </div>
                                    )}
                            </div>
                            {this.props.family && (
                                <div className={Styles.PanelPlusLoin__right}>
                                    <h4
                                        className={`teasing-3 ${Styles.PanelPlusLoin__right__title}`}
                                    >
                                        Les autres sites de la famille{" "}
                                        {this.props.family.niceName}
                                    </h4>
                                    <StaticQuery
                                        query={query}
                                        render={(data) => {
                                            if (
                                                this.helpers.utils
                                                    .isMobileTablet
                                            ) {
                                                return this.renderMobileContentWithData(
                                                    data.allSitesJson.edges
                                                )
                                            } else {
                                                return this.renderDesktopContentWithData(
                                                    data.allSitesJson.edges
                                                )
                                            }
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </Cell>
                </Row>
            </Element>
        )
    }
}

// Query
export const query = graphql`
    query SitesLinkedQuery {
        allSitesJson {
            edges {
                node {
                    id
                    chapo
                    chartsValues {
                        business
                        vision
                        service
                        reference
                    }
                    families {
                        primary
                        secondary
                    }
                    name
                }
            }
        }
    }
`

export default PanelPlusLoin
