import React, { Component } from "react"
import { Link, navigate } from "gatsby"

// Styles
import Styles from "./PanelIntro.module.styl"

// Components
import { Row, Cell } from "~layout/Layout"

// Libs
import anime from "animejs/lib/anime.es.js"

class PanelIntro extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {}

    render() {
        return (
            <div className={`${Styles.PanelIntro}`} ref={this.panelIntro}>
                <Row>
                    <Cell start="2" end="12">
                        <div className={Styles.PanelIntro__inner}>
                            <div className={Styles.PanelIntro__top}>
                                <h2 className="teasing-2">
                                    {this.props.chapo}
                                </h2>
                            </div>
                            <div className={`${Styles.PanelIntro__body} rte`}>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: this.props.description,
                                    }}
                                ></p>
                            </div>
                        </div>
                    </Cell>
                </Row>
            </div>
        )
    }
}

export default PanelIntro
