import React, { Component } from "react"

// Styles
import Styles from "./ListDetails.module.styl"

// Components
import CardDetail from "~components/Cards/CardDetail"

class ListDetails extends Component {
    constructor(props) {
        super(props)
    }

    renderItems() {
        const Items = this.props.detailsItems.map((item, index) => {
            return (
                <li
                    className={`${Styles.ListDetails__item}`}
                    key={`list-details-item-${index}`}
                >
                    <CardDetail img={item.imgPath} content={item.content} />
                </li>
            )
        })

        return Items
    }

    render() {
        const Items = this.renderItems()

        return <ul className={`${Styles.ListDetails}`}>{Items}</ul>
    }
}

export default ListDetails
