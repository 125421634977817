import TopBarFilters from "./TopBarFilters"

class TopBarResultat extends TopBarFilters {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.setEntries()
    }

    setEntries() {
        const entries = Object.values(this.props.data).map((entry, index) => {
            return {
                href: `${entry.slug}`,
                name: `${entry.name}`,
            }
        })

        this.setState({
            entries: entries,
        })
    }
}

export default TopBarResultat
