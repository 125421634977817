import React, { Component } from "react"

// Components
import ExpandPriorite from "./ExpandPriorite"

// Libs
import { scroller } from "react-scroll"

class ExpandsController extends Component {
    constructor(props) {
        super(props)

        // ES6 Rebind
        this.closeAllExpands = this.closeAllExpands.bind(this)
    }

    componentDidMount() {}

    closeAllExpands(openingID) {
        this.expandRefs.forEach((expand, index) => {
            if (expand) {
                if (index !== openingID && expand.state.isOpen) {
                    expand.closeExpand()
                }
            }
        })
    }

    scrollToExpand(openingID) {
        setTimeout(() => {
            scroller.scrollTo(`expand-${this.props.type}-${openingID}`, {
                smooth: true,
                offset: -135,
                duration: 350,
            })
        }, 650)
    }

    renderItems() {
        this.expandRefs = []

        const Items = this.props.items.map((item, index) => {
            if (this.props.type === "priorite") {
                return (
                    <ExpandPriorite
                        name={`expand-priorite-${index}`}
                        ref={(ref) => {
                            this.expandRefs.push(ref)
                        }}
                        key={`expand-priorite-${index}`}
                        itemIndex={index}
                        onOpen={(openingID) => {
                            if (this.props.closeOthers) {
                                this.closeAllExpands(openingID)
                            }

                            if (this.props.scrollTo) {
                                this.scrollToExpand(openingID)
                            }
                        }}
                        {...item}
                    />
                )
            }
        })

        return Items
    }

    render() {
        const ExpandItems = this.renderItems()

        return ExpandItems
    }
}

export default ExpandsController
