import React, { Component } from "react"

// Styles
import Styles from "./CardDetail.module.styl"

// Components
import Image from "~components/Images/Image"

class CardDetail extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div
                className={`${Styles.CardDetail} ${
                    this.props.img ? "" : Styles.CardDetail__no_pic
                }`}
            >
                <div className={`${Styles.CardDetail__inner}`}>
                    {this.props.img && (
                        <div className={`${Styles.CardDetail__left}`}>
                            <Image
                                src={this.props.img}
                                className={`${Styles.CardDetail__img}`}
                            />
                        </div>
                    )}
                    <div className={`${Styles.CardDetail__right}`}>
                        <h4 className={`teasing-3`}>{this.props.content}</h4>
                    </div>
                </div>
            </div>
        )
    }
}

export default CardDetail
