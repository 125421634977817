import React, { Component } from "react"

// Styles
import Styles from "./BoxCarouselIdea.module.styl"

class BoxCarouselIdea extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className={Styles.BoxCarouselIdea}>
                <div className={Styles.BoxCarouselIdea__inner}>
                    <div className={Styles.BoxCarouselIdea__top}>
                        {this.props.label && (
                            <span
                                className={`${Styles.BoxCarouselIdea__label} very-small u-f-upper`}
                            >
                                {this.props.label}
                            </span>
                        )}
                        <h3 className={`heading-3`}>{this.props.title}</h3>
                    </div>
                    <div className={Styles.BoxCarouselIdea__body}>
                        <div
                            className={`rte`}
                            dangerouslySetInnerHTML={{
                                __html: this.props.content,
                            }}
                        ></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BoxCarouselIdea
