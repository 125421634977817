import React, { Component } from "react"

// Styles
import Styles from "./ExpandPriorite.module.styl"

// Components
import Icon from "~icons/Icon"

// Libs
import { Element } from "react-scroll"

class ExpandPriorite extends Component {
    constructor(props) {
        super(props)

        // Refs
        this.expandInner = React.createRef()
        this.expandBtn = React.createRef()
        this.expandContent = React.createRef()

        // ES6 rebind
        this.toggleExpand = this.toggleExpand.bind(this)
        this.onResize = this.onResize.bind(this)
    }

    state = {
        isOpen: false,
        sizes: {},
    }

    componentDidMount() {
        this.bindEvents()
        this.storeSizes()
    }

    componentWillUnmount() {
        this.unbindEvents()
        this.clearTimer()
    }

    bindEvents() {
        window.addEventListener("resize", this.onResize)
    }

    unbindEvents() {
        window.removeEventListener("resize", this.onResize)
    }

    onResize() {
        this.storeSizes()
    }

    storeSizes() {
        this.storeTimer = setTimeout(() => {
            this.setState(
                {
                    isOpen: false,
                    sizes: {
                        height: this.expandInner.current.clientHeight,
                        deployedHeight:
                            this.expandInner.current.clientHeight +
                            this.expandContent.current.clientHeight,
                    },
                },
                () => {
                    this.clearTimer()
                }
            )
        }, 500)
    }

    clearTimer() {
        if (this.storeTimer) {
            clearTimeout(this.storeTimer)
            this.storeTimer = null
        }
    }

    toggleExpand() {
        if (this.state.isOpen) {
            this.closeExpand()
        } else {
            this.openExpand()
        }
    }

    openExpand() {
        this.setState(
            {
                isOpen: true,
            },
            () => {
                if (this.state.isOpen) {
                    this.props.onOpen(this.props.itemIndex)
                }
            }
        )
    }

    closeExpand() {
        this.setState({
            isOpen: false,
        })
    }

    renderScoreBullets() {
        const Bullets = [0, 1, 2, 3, 4].map((item, index) => {
            return (
                <i
                    key={`bullet-expand-${index}`}
                    className={`${Styles.ExpandPriorite__score__bullet} ${
                        index < this.props.score
                            ? Styles.ExpandPriorite__score__bullet__is_active
                            : ""
                    }`}
                ></i>
            )
        })

        return Bullets
    }

    render() {
        const ScoreBullets = this.renderScoreBullets()

        return (
            <Element
                name={`expand-priorite-${this.props.itemIndex}`}
                className={`${Styles.ExpandPriorite} ${
                    this.state.isOpen && typeof window !== "undefined"
                        ? Styles.ExpandPriorite__is_open
                        : ""
                } expand--priorite`}
            >
                <div
                    className={Styles.ExpandPriorite__inner}
                    ref={this.expandInner}
                    style={{
                        height: `${
                            this.state.isOpen && typeof window !== "undefined"
                                ? this.state.sizes.deployedHeight
                                : this.state.sizes.height
                        }px`,
                    }}
                >
                    <div
                        className={Styles.ExpandPriorite__top}
                        ref={this.expandBtn}
                        onClick={this.toggleExpand}
                    >
                        <div className={Styles.ExpandPriorite__top__left}>
                            <span
                                className={`${Styles.ExpandPriorite__type} expand__label`}
                            >
                                {this.props.type}
                            </span>
                            <div className={Styles.ExpandPriorite__score}>
                                {ScoreBullets}
                            </div>
                            <h4
                                className={`${Styles.ExpandPriorite__title} teasing-3`}
                            >
                                {this.props.title}
                            </h4>
                        </div>
                        <div className={Styles.ExpandPriorite__top__right}>
                            {this.state.isOpen &&
                                typeof window !== "undefined" && (
                                    <Icon name="minus" height={32} width={32} />
                                )}
                            {!this.state.isOpen &&
                                typeof window !== "undefined" && (
                                    <Icon name="plus" height={32} width={32} />
                                )}
                        </div>
                    </div>
                    <div
                        className={`${Styles.ExpandPriorite__content} ${
                            !this.props.content.imgPath
                                ? Styles.ExpandPriorite__content__no_pic
                                : ""
                        }`}
                        ref={this.expandContent}
                    >
                        <div className={Styles.ExpandPriorite__content__left}>
                            {this.props.content.chapo && (
                                <p
                                    className={`${Styles.ExpandPriorite__content__chapo} teasing-3`}
                                >
                                    {this.props.content.chapo}
                                </p>
                            )}
                            {this.props.content.text && (
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: this.props.content.text,
                                    }}
                                ></p>
                            )}
                        </div>
                        {this.props.content.imgPath && (
                            <div
                                className={
                                    Styles.ExpandPriorite__content__right
                                }
                            >
                                <img
                                    src={this.props.content.imgPath}
                                    className={
                                        Styles.ExpandPriorite__content__img
                                    }
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Element>
        )
    }
}

export default ExpandPriorite
