import React, { Component } from "react"

// Styles
import Styles from "./ListRisques.module.styl"

class ListRisques extends Component {
    constructor(props) {
        super(props)
    }

    renderListItems() {
        const Items = this.props.items.map((item, index) => {
            return (
                <li
                    key={`list-risques-item-${index}`}
                    className={`${Styles.ListRisques__item}`}
                >
                    <div className={`${Styles.ListRisques__item__top}`}>
                        <span className={`number`}>{index + 1}</span>
                    </div>
                    <div className={`${Styles.ListRisques__item__body} rte`}>
                        <p
                            dangerouslySetInnerHTML={{ __html: item.content }}
                        ></p>
                    </div>
                </li>
            )
        })

        return Items
    }

    render() {
        const Items = this.renderListItems()

        return <ul className={`${Styles.ListRisques}`}>{Items}</ul>
    }
}

export default ListRisques
