import React, { Component } from "react"

// Libs
import { Element } from "react-scroll"

// Styles
import CommonStyles from "./PanelCommons.module.styl"
import Styles from "./PanelRisques.module.styl"

// Components
import { Row, Cell } from "~layout/Layout"
import ListRisques from "~components/Lists/ListRisques"

class PanelRisques extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Element
                name="risques"
                className={`${Styles.PanelRisques} ${CommonStyles.PanelResultat}`}
            >
                <Row>
                    <Cell start="0" end="12">
                        <div className={Styles.PanelRisques__inner}>
                            <div className={Styles.PanelRisques__top}>
                                <h3 className="heading-3-bis">
                                    Risques & Arbitrages
                                </h3>
                                <ListRisques items={this.props.items} />
                            </div>
                        </div>
                    </Cell>
                </Row>
            </Element>
        )
    }
}

export default PanelRisques
