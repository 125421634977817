import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Image = ({ src, ...props }) => {
    const data = useStaticQuery(graphql`
        query {
            allFile(
                filter: {
                    relativeDirectory: { eq: "images/content" }
                    internal: { mediaType: { regex: "/image/" } }
                }
            ) {
                edges {
                    node {
                        name
                        extension
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    `)

    const match = useMemo(
        () =>
            data.allFile.edges.find(
                ({ node }) => src === `${node.name}.${node.extension}`
            ),
        [data, src]
    )

    return <Img fluid={match.node.childImageSharp.fluid} {...props} />
}

export default Image
